$color-black: #000000;
$color-white: #FFFFFF;
$color-blue: #006E96;

.row {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
}

input:-internal-autofill-selected {
    background-color: rgb(0, 0, 0) !important;
}


.page-login {
    width: 100%;
    .description {
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
        color: $color-black;
        font-size: 16px;
        font-weight: 300;
        line-height: 18px;
        max-width: 464px;
    }
    small {
        color: $color-black;
    }
    .form-login {
        margin: 40px auto;
        padding: 50px 70px;
        max-width: 530px;
        //background-color: #338bac;
        background-color: rgba(244, 244, 243, 0.7);
        .info-img {
            margin-bottom: 43px;
            margin-top: 43px;
        }
        .login-field {
            display: flex;
            align-items: center;
            margin-bottom: 28px;
            img {
                width: 25px;
                margin-right: 15px;
            }
            .form-group {
                max-width: calc(100% - 46px);
                flex: 0 0 calc(100% - 46px);
            }
            input {
                /*background-color: rgb(232, 240, 254);*/
                background-color: $color-white;
                &::placeholder {
                    text-transform: uppercase;
                    color: $color-black;
                }
                
            }
        }
        input, button {
            font-size: 16px;
            line-height: 19px;
            font-family: 'Montserrat Medium';
            color: $color-black;
            height: 40px;
        }
        button.btn-success,
        button.btn-success:active {
            margin-left: 46px;
            width: calc(100% - 46px);
            background-color: $color-blue;
            border-color: $color-blue;
            color: $color-white;
            text-transform: uppercase;
            font-weight: 500;
            box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
            font-family: 'Montserrat SemiBold';
        }
        button.btn-success:focus {
            box-shadow: none;
        }
        .password-forgot {
            margin-left: 46px;
            width: calc(100% - 46px);
            margin-top: 30px;
            a {
                color: $color-black;
                font-size: 12px;
                line-height: 15px;
                font-family: 'Montserrat SemiBold';
            }
        }
    }
    .form-group {
        margin-bottom: 0;
    }
    input, button {
        border-radius: 0;
    }
}

input {
  filter: none;
}


@media (max-width: 767px) {
    .page-login {
        .description {
            text-align: center;
        }
    }
}
@media (max-width: 475px) {
    .page-login {
        .form-login {
            padding: 25px;
        }
    }
}